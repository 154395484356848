import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,[_c(VCardTitle,{staticClass:"bg-gradient-primary-2"},[_c('span',{staticClass:"headline white--text"},[_vm._v("Approval Details")])]),(_vm.checkObject(_vm.approvalDetail))?_c('div',{staticClass:"body"},[_c(VCardText,{staticClass:"pt-4"},[_c('InfoBlock',{attrs:{"label":"User","value":_vm.approvalDetail.userName}}),_c('InfoBlock',{attrs:{"label":"Request Date","value":_vm.formateDate(_vm.approvalDetail.approvalDate)}}),_c('InfoBlock',{attrs:{"label":"Request Action","value":_vm.approvalDetail.buyOrSell}}),_c('InfoBlock',{attrs:{"label":"Name Of Security","value":_vm.approvalDetail.security}}),_c('InfoBlock',{attrs:{"label":"Ticker","value":_vm.approvalDetail.ticker}}),_c('InfoBlock',{attrs:{"label":"Exchange","value":_vm.approvalDetail.exchange}}),_c('InfoBlock',{attrs:{"label":"Quantity","value":_vm.approvalDetail.quantity}}),_c('InfoBlock',{attrs:{"label":"GTC Order","value":_vm.approvalDetail.gtcOrder}}),_c('InfoBlock',{attrs:{"label":"Investment class","value":_vm.approvalDetail.investmentClass}}),_c('InfoBlock',{attrs:{"label":"Account Information","value":_vm.approvalDetail.accountNumber}}),_c('InfoBlock',{attrs:{"label":"Broker","value":_vm.approvalDetail.broker}})],1),_c(VCardText,{staticClass:"declaration"},[_c('div',{staticClass:"elevation-2 rounded"},[_c('div',{staticClass:"heading bg-gradient-primary-2 elevation-2 rounded"},[_vm._v("Declaration")]),_c('div',{staticClass:"py-2 px-4",domProps:{"innerHTML":_vm._s(_vm.approvalDetail.settings.declaration)}})])])],1):_vm._e(),_c(VCardActions,{staticClass:"py-5"},[_c(VSpacer),_c('ac-button',{attrs:{"title":"Close","color":"error","outlined":""},on:{"click":_vm.onClose}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }