<template>
  <v-dialog v-model="value" max-width="800px">
    <v-card>
      <v-card-title class="bg-gradient-primary-2">
        <span class="headline white--text">Approval Details</span>
      </v-card-title>
      <div v-if="checkObject(approvalDetail)" class="body">
        <v-card-text class="pt-4">
          <InfoBlock label="User" :value="approvalDetail.userName" />
          <InfoBlock label="Request Date" :value="formateDate(approvalDetail.approvalDate)" />
          <InfoBlock label="Request Action" :value="approvalDetail.buyOrSell" />
          <InfoBlock label="Name Of Security" :value="approvalDetail.security" />
          <InfoBlock label="Ticker" :value="approvalDetail.ticker" />
          <InfoBlock label="Exchange" :value="approvalDetail.exchange" />
          <InfoBlock label="Quantity" :value="approvalDetail.quantity" />
          <InfoBlock label="GTC Order" :value="approvalDetail.gtcOrder" />
          <InfoBlock label="Investment class" :value="approvalDetail.investmentClass" />
          <InfoBlock label="Account Information" :value="approvalDetail.accountNumber" />
          <InfoBlock label="Broker" :value="approvalDetail.broker" />
        </v-card-text>
        <v-card-text class="declaration">
          <div class="elevation-2 rounded">
            <div class="heading bg-gradient-primary-2 elevation-2 rounded">Declaration</div>
            <div class="py-2 px-4" v-html="approvalDetail.settings.declaration"></div>
          </div>
        </v-card-text>
      </div>
      <v-card-actions class="py-5">
        <v-spacer></v-spacer>
        <ac-button title="Close" color="error" outlined @click="onClose" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'ApprovalDetailsModal',
  components: {
    AcButton: () => import('@/components/AcButton'),
    InfoBlock: () => import('@/components/PaDealing/PaRequests/PaRequestDetailsModal/InfoBlock'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    approvalDetail: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onClose() {
      this.$emit('input', false)
    },
    formateDate(date) {
      if (!date) return null
      return moment(date).format('DD MMM YYYY HH:MM:SS')
    },
  },
}
</script>

<style lang="scss" scoped>
.body {
  max-height: 70vh;
  overflow-y: auto;
}
.declaration,
.approvals {
  .heading {
    color: white;
    font-weight: 600;
    width: 30%;
    white-space: nowrap;
    padding: 0.5em 1em;
    margin-bottom: 0.5em;
    font-size: 1.2em;
  }
}
</style>
